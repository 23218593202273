<template>
  <div class="data">
    <v-header />
    <div style="height: 70px;"></div>
    <div class="tab">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>关于我们</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="con">
      <div class="con-left">
        <p class="sp sp-select">网站声明</p>
        <p class="sp">平台简介</p>
        <p class="sp">人才招聘</p>
        <p class="sp">注册协议</p>
        <p class="sp">隐私政策</p>
        <p class="sp">常见问题</p>
      </div>
      <div class="con-right">
          <div class="datas">关于我们</div>
      </div>
    </div>
     <v-footer />
  </div>
</template>

<script>
import header from "@/components/header";
import footer from "@/components/footer"
export default {
  components: {
    "v-header": header,
    "v-footer": footer
  }
};
</script>

<style lang="less" scoped>
.data {
  //   min-height: 100vh;
  background: #f5f6f7;
}
.con {
    width: 1200px;
    margin: auto;
    overflow: hidden;
    &-left {
        width: 20%;
        float: left;
        padding-top: 5px;
        padding-bottom: 15px;
        background: #fff;
        .sp {
            width: 100%;
            height: 50px;
            line-height: 50px;
            text-indent: 30px;
            font-size: 15px;
            cursor: pointer;
        }
    }
    &-right {
        width: 79%;
        min-height: 500px;
        float: right;
        background: #fff;
        .datas {
            width: 95%;
            padding-top: 20px;
            margin: auto;
        }
    }
}
</style>